<template>
    <v-storyblok-story :story="story ?? undefined" />
</template>

<script setup lang="ts">
const { t } = useI18n({ useScope: 'global' });
const config = useRuntimeConfig();

const story = await useAsyncStoryblokStory(t('storyblok.home'));

/* eslint-disable @typescript-eslint/naming-convention */
useJsonld({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'Masku',
    alternateName: t('site.name'),
    url: `${config.public.appUrl}`,
});
/* eslint-enable @typescript-eslint/naming-convention */

const siteTitle = t('masku-url');

useSeoMeta({
    title: story?.content.meta?.title || story?.name,
    titleTemplate(title) {
        return title || siteTitle;
    },
    ogTitle: story?.content.meta?.title ?? story?.name,
    description: story?.content.meta?.description ?? undefined,
    ogDescription: story?.content.meta?.description ?? undefined,
});
</script>
